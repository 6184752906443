import './index.css';
import HeroImg from '../../assets/hero-img.svg';

function Hero() {
  return (
    <div className="hero">
      <img src={HeroImg} className="hero-img" />
    </div>
  );
}

export default Hero;
