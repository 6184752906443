import React from 'react';

import Dovzhenko from '../../assets/dovzhenko.svg';
import Mizhvukhamy from '../../assets/mizhvukhamy.svg';
import './index.css';

function Footer() {
  return (
    <div className="footer" id="footer">
      <img src={Dovzhenko} />
      <img className="mizhvukhamy-img" src={Mizhvukhamy} />
      <div className="contact-block">
        <div className="contact-title">КОНТАКТИ</div>
        <div className="phone">+38 (044) 284-19-15.</div>
        <div className="email">Email@gmail.com</div>
      </div>
    </div>
  );
}

export default Footer;
