import React, { useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

import HamburgerImg from '../../assets/hamburger.svg';

import './index.css';

function Header() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <div className="header">
      <nav className="navbar">
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <VscChromeClose size={30} className="hamburger-img close" />
          ) : (
            <img src={HamburgerImg} className="hamburger-img" />
          )}
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
            <a href="#news" onClick={handleClick}>
              Новини
            </a>
          </li>
          <li onClick={handleClick} className="nav-item">
            <a href="#about">Про ініціативу</a>
          </li>
          <li className="nav-item" onClick={handleClick}>
            <a href="#download">Листи-звернення</a>
          </li>
          <li className="nav-item" onClick={handleClick}>
            <a href="#footer">Контакти</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
