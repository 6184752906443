import React from 'react';

import Image1 from '../../assets/news/n1.png';
import Image2 from '../../assets/news/n2.jpg';

import './index.css';

function News() {
  return (
    <div className="news" id="news">
      <div className="title">Новини</div>
      <div className="content-wrapper">
        <a
          className="article"
          target="_blank"
          href="https://m.facebook.com/photo.php?fbid=6206292806125250&id=201931749894749&set=a.202232086531382&source=57&eav=AfYwwbjkp2vFv33GnkAZv7EUoMoukZdKpNuWAFP52K8PL5K5WPSm1G8oZ_fR2lHTwB4&paipv=0"
        >
          <img className="image" src={Image1} />
          <div className="article-title">
            Сьогодні відбулося жеребкування для відбору кандидатур від
            Громадських організацій для включення до складу комісії з проведення
            конкурсу на посаду керівника Довженко-Центру
          </div>
          <div className="text">
            Шляхом жеребкування обрали наступні кандидатури:...
          </div>
          <div className="date">9 січня 2023</div>
        </a>
        <a
          className="article"
          target="_blank"
          href="https://m.facebook.com/dovzhenko.centre/photos/a.675153795905873/6203949466359584/?type=3&av=100002533519164&eav=Afad0i99VQnN7bIqo-OceY_4PTqtIsPx0dSynYzRJ0ymJekFUi9EksHNIKcv3Hdf1_w&source=57&paipv=0"
        >
          <img className="image" src={Image2} />
          <div className="article-title">
            У понеділок, 9 січня, об 11:00 розпочнеться онлайн-трансляц
          </div>
          <div className="text">
            Конкурсна комісія (особи, що будуть обирати переможця/переможицю)
            формується з трьох груп: 3 людини від Держкіно, 3 людини від
            Довженко-Центру...
          </div>
          <div className="date">8 січня 2023</div>
        </a>
      </div>
    </div>
  );
}

export default News;
