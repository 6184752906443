import React, { useState } from 'react';

import Join from '../Join';

import './index.css';

function About() {
  const [isModalShown, setIsModalShown] = useState(false);

  const handleClick = () => {
    setIsModalShown(true);
  };

  const closeModal = () => {
    setIsModalShown(false);
  };

  return (
    <>
      <div className="about" id="about">
        <div className="title">Про ініціативу</div>
        <div className="text">
          <div className="subtitle">Довженко-центр на межі знищення</div>
          <div className="content">
            Національний центр Олександра Довженка успішно поєднує в собі музей
            кіно, дослідницький центр, кінокопіювальну лабораторію та
            фільмофонд. Тут зберігається унікальна спадщина українського кіно:
            <ul>
              <li>Більше 60 000 кіноплівок </li>
              <li>7000 одиниць архівних матеріалів</li>
              <li>4000 видань у бібліотеці</li>
            </ul>
            А ще Довженко-центр – це незалежна інституція, яка відмовляється
            грати за тіньовими правилами корумпованих чиновників. Саме тому його
            систематично намагаються зламати представники органів влади.
          </div>
          <div className="subtitle">
            Більше року Міністерство культури саботує призначення керівника
            центру.
          </div>
          <div className="content">
            Ще в 2021 році Олена Гончарук чесно перемогла у конкурсі на посаду
            директора Довженко-центру. Наказ про її призначення не підписаний
            досі.
          </div>
          <div className="subtitle">
            Колекцію центру відбирають і розпорошують.
          </div>
          <div className="content">
            У серпні 2022 року Держкіно видало указ про «реорганізацію»
            Довженко-центру. Всі фільми передають «Науковому центру
            кінематографії України» – сплячій держустанові, яка вже 10 років не
            здійснює діяльності і не має працівників. Окремо у центру відбирають
            права на колекцію анімації.
          </div>
          <div className="subtitle">Будівля центру під загрозою.</div>
          <div className="content">
            Вже за місяць після «реорганізації» Держкіно дозволило приватизувати
            два корпуси Довженко-центру. Тим часом впритул до головної будівлі
            будується великий ЖК.
          </div>
          <div className="subtitle">
            Держкіно незаконно змінює керівництво центру.
          </div>
          <div className="content">
            За результатами перевірки, проведеної з цілою низкою порушень,
            Держкіно заявило про неефективність Довженко-центру. Замість Олени
            Гончарук призначено «кризового менеджера» Юлію Каждан, відому
            здобутками в кінології та гіпнотерапії.
          </div>
          <div className="subtitle"> Думку спільноти цинічно ігнорують.</div>
          <div className="content">
            Петиція про скасування «реорганізації» набрала 25 000 підписів.
            Формальна відповідь Кабміну ніяк не покращила ситуацію.
          </div>
          <div className="subtitle">
            Зберегти Довженко-центр – наша спільна справа.
          </div>
          <div className="content">
            Знищення української культури під час війни – це злочин, за який ми
            будемо розплачуватись роками. Адже саме культура формує національну
            ідентичність, за яку ми зараз б'ємося на фронті.
          </div>
          <div className="subtitle">
            Не дозволимо чиновникам забути про Довженко-центр!
          </div>
          <div className="content">
            Ми підготували список держустанов, які мають вплив на ситуацію, та
            шаблони листів, які пропонуємо їм надсилати. Завантажуйте,
            підписуйте та нагадуйте чиновникам, чиї інтереси вони мають
            представляти.
          </div>
        </div>
        <button className="button" onClick={handleClick}>
          Долучитися
        </button>
      </div>
      {isModalShown ? <Join closeModal={closeModal} /> : null}
    </>
  );
}

export default About;
