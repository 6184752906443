import React, { useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

import './index.css';

function Join({ closeModal }) {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="join">
      <VscChromeClose onClick={closeModal} size={30} className="close" />
      <div className="title">Долучитися</div>
      <form>
        <div>
          <label>ПІБ</label>
          <input type="text" required />
        </div>
        <div>
          <label>Телефон</label>
          <input type="tel" required />
        </div>
        <div>
          <label>E-mail</label>
          <input type="email" required />
        </div>
        <div>
          <label>До якої професійної галузі належите?</label>
          <input type="text" required />
        </div>
        <div>
          <label>Яка ваша мотивація стати партнером ініціативи?</label>
          <textarea type="text" required />
        </div>
        <div className="checkbox">
          <input type="checkbox" onChange={handleCheckbox} required />
          <label>Даю згоду на обробку особистих даних</label>
        </div>
        <button
          className="button"
          disabled={!isChecked}
          onClick={() => closeModal()}
        >
          Зареєструватись
        </button>
      </form>
    </div>
  );
}

export default Join;
