import React from 'react';

import './index.css';

function Download() {
  const onClickHandler = () => {
    fetch('pdf/SamplePDF.pdf').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.click();
      });
    });
  };

  const onClickHandler1 = () => {
    fetch('pdf/SamplePDF1.pdf').then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF1.pdf';
        alink.click();
      });
    });
  };
  return (
    <div className="download" id="download">
      <div className="title">Завантажити і підписати листи</div>
      <div className="title-mob">
        <div className="t1">Завантажити і</div>
        <div className="t2">підписати листи</div>
      </div>
      <div className="content-wrapper">
        <div className="subtitle">
          Листи на підпис і відправлення в різні інстанціїї. Скачайте і
          відправте на зазначені адреси.
        </div>
        <div className="letters">
          <span>Лист для звернення в Мінкульт</span>
          <button className="download-btn" onClick={onClickHandler}>
            Завантажити
          </button>
        </div>

        <div className="letters">
          <span>Лист для звернення в Мінкульт</span>
          <button className="download-btn" onClick={onClickHandler1}>
            Завантажити
          </button>
        </div>
        <a
          href="https://drive.google.com/drive/folders/1JFfEasv3mvd9SpUvg1Wxee71e2-y-o8L"
          target="_blank"
          className="button"
        >
          Завантажити все
        </a>
      </div>
    </div>
  );
}

export default Download;
